var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar-header-wrapper",class:{
    'fixed-mobile-top': !_vm.withWave && !_vm.largeWave && (_vm.fixedMobile || _vm.hasSlot),
    'has-scrollable-content': _vm.hasSlot,
    'scrollable-content-visible': _vm.hasSlot && _vm.scrollPositionReached
  },style:(("z-index: " + _vm.zIndex))},[(_vm.withWave)?_c('div',{class:{
      'navbar-header-wave': _vm.withWave,
      'large-wave': _vm.largeWave,
      'simple-wave': _vm.simpleWave
    }}):_vm._e(),_vm._v(" "),_c('b-navbar',{staticClass:"navbar-header",attrs:{"toggleable":"lg","type":"dark"}},[_c('b-navbar-brand',{staticClass:"d-inline-flex align-items-center",attrs:{"href":_vm.homeUrl}},[_c('logo-brand',{attrs:{"force-white-logo-in-mobile":"","alternate":_vm.withWave && !_vm.largeWave && !_vm.simpleWave}})],1),_vm._v(" "),(_vm.withWave)?_c('blob-mobile',{staticClass:"blob-header-mobile d-lg-none"}):_vm._e()],1),_vm._v(" "),_c('section',{staticClass:"navbar-scroll"},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }