var render = function (_h,_vm) {var _c=_vm._c;return _c('div',{staticClass:"bg-light py-7"},[_c('b-container',[_c('b-row',{staticClass:"align-items-center justify-content-center"},[_c('b-col',{attrs:{"md":"10","lg":"9"}},[_c('article',{staticClass:"testimonial-slider"},[_c('section',{directives:[{name:"swiper",rawName:"v-swiper:swiperReviews",value:({
              navigation: {
                nextEl: '.btn-carousel-next',
                prevEl: '.btn-carousel-prev',
                disabledClass: 'btn-carousel-disabled'
              },
              slidesPerView: 1,
              spaceBetween: 30
            }),expression:"{\n              navigation: {\n                nextEl: '.btn-carousel-next',\n                prevEl: '.btn-carousel-prev',\n                disabledClass: 'btn-carousel-disabled'\n              },\n              slidesPerView: 1,\n              spaceBetween: 30\n            }",arg:"swiperReviews"}]},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.props.testimonials),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('article',{staticClass:"testimonial-slide d-flex flex-column flex-md-row align-items-stretch"},[_c('div',{staticClass:"mt-0 text-center"},[_c('avatar',{staticClass:"mr-4",attrs:{"avatar-url":item.avatarUrl,"size":"260px","alt-text":item.fullName}})],1),_vm._v(" "),_c('div',{staticClass:"testimonial-slide-content d-flex flex-column justify-content-between ty-5 ml-md-5 text-center text-md-left"},[_c('div',{staticClass:"h3  mb-3 mt-3 mt-md-5 font-weight-normal"},[_vm._v("\n                      "+_vm._s(item.text)+"\n                    ")]),_vm._v(" "),_c('div',[_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v("\n                        "+_vm._s(item.fullName)+"\n                      ")]),_vm._v(" "),_c('span',[_vm._v("\n                        "+_vm._s(item.placeName)+"\n                      ")])])])])])}),0)]),_vm._v(" "),_c('section',{staticClass:"d-flex justify-content-center mt-5 mt-md-0"},[_c('b-button',{staticClass:"btn-carousel btn-carousel-prev",attrs:{"variant":"outline-success"}},[_c('arrow-right',{staticClass:"flip-h"})],1),_vm._v(" "),_c('b-button',{staticClass:"btn-carousel btn-carousel-next",attrs:{"variant":"outline-success"}},[_c('arrow-right')],1)],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }