



































import Vue, { PropOptions } from 'vue'

export type ValuePoint = {
  title: string
  description: string
}

export default Vue.extend({
  name: 'ValuePoints',
  functional: true,
  props: {
    valuePoints: {
      type: Array,
      required: true
    } as PropOptions<Array<ValuePoint>>
  }
})
