





































import Vue, { PropOptions } from 'vue'

export type FAQ = {
  title: string
  text: string
}

export default Vue.extend({
  name: 'ListPracticeFAQs',
  props: {
    faqs: {
      type: Array,
      required: true
    } as PropOptions<FAQ[]>
  },
  computed: {
    listLeft(): FAQ[] {
      return this.faqs.filter((_, index) => index % 2 === 0)
    },
    listRight(): FAQ[] {
      return this.faqs.filter((_, index) => index % 2 !== 0)
    },
    jointList(): FAQ[][] {
      return [this.listLeft, this.listRight]
    }
  }
})
