


































import Vue, { PropOptions } from 'vue'

export interface ListPracticeReasonWhy {
  iconUrl: string
  title: string
  description: string
}

export default Vue.extend({
  name: 'WhyJoin',
  functional: true,
  props: {
    title: {
      type: String,
      required: true
    },
    reasons: {
      type: Array,
      required: true
    } as PropOptions<Array<ListPracticeReasonWhy>>,
    onCtaClick: {
      type: Function,
      required: true
    } as PropOptions<() => void>
  }
})
