






































import Vue, { PropOptions } from 'vue'
import { RootState } from 'HealthAdvisorFrontendApp'
import { logoutWithBackend } from '@/utils/auth'
import LogoBrand from '@/components/shared/LogoBrand/index.vue'
import BlobMobile from '~/static/waves/blob-header-mobile.svg?inline'

export default Vue.extend({
  name: 'NavbarHeader',
  components: {
    LogoBrand,
    BlobMobile
  },
  props: {
    withWave: {
      type: Boolean,
      default: undefined
    },
    largeWave: {
      type: Boolean,
      default: undefined
    },
    simpleWave: {
      type: Boolean,
      default: undefined
    },
    fixedMobile: {
      type: Boolean,
      default: false
    },
    showNavbarScrollAtScrollPosition: {
      type: Number,
      default: undefined
    } as PropOptions<number | undefined>
  },
  data() {
    return {
      zIndex: 0,
      searchFormVisibile: false,
      hasSlot: Boolean(this.$slots.default),
      scrollPositionReached: false,
      userName: undefined as string | undefined,
      homeUrl: process.env.API_PROXY_URL
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)

    // check log status
    const { actor } = this.$store.state as RootState
    this.userName = actor?.firstName
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    // we need to toggle css on mobile collapse so element close to the wave
    // won't get overlapped and can be clicked
    giveZIndex() {
      this.$data.zIndex = 1
    },
    removeZIndex() {
      this.$data.zIndex = 0
    },
    toggleSearch() {
      // when opening / closing search form we do need zindex to our main container
      this.$data.searchFormVisibile = !this.$data.searchFormVisibile
      this.$data.zIndex = this.$data.searchFormVisibile ? 1030 : 0 // bootstrap default for $zindex-fixed
    },
    handleScroll() {
      const scrollPoint = this.$props.showNavbarScrollAtScrollPosition || 700
      this.$data.scrollPositionReached = window.scrollY > scrollPoint
    },
    logout() {
      const { oktaSiginWidget } = this.$store.state as RootState
      logoutWithBackend(oktaSiginWidget, this.$axios).then(() => {
        this.userName = undefined
        this.$store.dispatch('flushActor')
      })
    }
  }
})
