















import Vue from 'vue'
import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.min.js'
import 'vue-slider-component/dist-css/vue-slider-component.css'

export default Vue.extend({
  name: 'RangeSlider',
  components: {
    VueSlider
  },
  props: {
    min: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    step: {
      type: Number,
      required: true
    },
    value: {
      type: Number,
      default: 0
    }
  },
  computed: {
    sliderValue: {
      get(): number {
        return this.value
      },
      set(val: number) {
        this.$emit('input', val)
      }
    }
  }
})
