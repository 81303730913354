














































































import Vue, { PropOptions } from 'vue'
import Avatar from '@/components/shared/Avatar/index.vue'
import ArrowRight from '~/static/icons/arrow-right.svg?inline'

export interface Testimonial {
  avatarUrl: string
  text: string
  fullName: string
  placeName: string
}

Vue.component('avatar', Avatar)
Vue.component('arrow-right', ArrowRight)

export default Vue.extend({
  name: 'CarouselReviews',
  functional: true,
  props: {
    testimonials: {
      type: Array,
      required: true
    } as PropOptions<Array<Testimonial>>
  }
})
