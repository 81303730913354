




































import Vue, { PropOptions } from 'vue'
import BottomWave from '~/static/waves/wave-list-practice-about-us-bottom.svg?inline'

Vue.component('bottom-wave', BottomWave)
export default Vue.extend({
  name: 'AboutUs',
  functional: true,
  props: {
    title: {
      type: String,
      required: true
    },
    textLeft: {
      type: String,
      required: true
    },
    textRight: {
      type: String,
      required: true
    },
    onCtaClick: {
      type: Function,
      required: true
    } as PropOptions<() => void>
  }
})
