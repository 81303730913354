
















































































































































import Vue, { PropOptions } from 'vue'
import RangeSlider from '@/components/shared/RangeSlider/index.vue'

export default Vue.extend({
  name: 'PricingModel',
  components: {
    RangeSlider
  },
  props: {
    onCtaClick: {
      type: Function,
      required: true
    } as PropOptions<() => void>
  },
  data() {
    return {
      initialAssessment: 40,
      initialAssessmentOptions: {
        min: 10,
        max: 90,
        step: 5
      },

      followUpFee: 35,
      followUpFeeOptions: {
        min: 10,
        max: 90,
        step: 5
      },

      averageTreatments: 4,
      averageTreatmentsOptions: {
        min: 1,
        max: 10,
        step: 1
      },

      clinicsNumber: 1,
      clinicsNumberOptions: {
        min: 1,
        max: 10,
        step: 1
      },

      monthlyNewPatients: 5,
      monthlyNewPatientsOptions: {
        min: 1,
        max: 10,
        step: 1
      }
    }
  },
  computed: {
    youMake(): number {
      const percentageDelta = 80
      const followupSessions = this.averageTreatments - 1
      const feeBase = (this.initialAssessment * percentageDelta) / 100
      const followUpsBase = feeBase + this.followUpFee * followupSessions
      const patientsBase = followUpsBase * this.monthlyNewPatients
      const youMake = patientsBase * this.clinicsNumber
      return youMake
    },
    perSessionPrice(): number {
      const commissionPerPatient = (this.initialAssessment * 20) / 100
      const totalCommision =
        commissionPerPatient * this.monthlyNewPatients * this.clinicsNumber
      const totalPatients =
        this.monthlyNewPatients * this.averageTreatments * this.clinicsNumber
      const costPerSession = totalCommision / totalPatients
      return costPerSession
    },
    oneTimeFee(): number {
      const initialAssessmentBase = (this.initialAssessment * 20) / 100
      return (
        initialAssessmentBase * this.monthlyNewPatients * this.clinicsNumber
      )
    },
    monthlyFee(): number {
      return 2.5 + this.clinicsNumber
    }
  },
  methods: {
    formatCurrency(value: number, noDecimals?: boolean) {
      const currencyFormatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP'
      })

      const formattedValue = currencyFormatter.format(value).replace('.00', '')
      return noDecimals ? formattedValue.split('.')[0] : formattedValue
    }
  }
})
